@charset "UTF-8";

/*
* Author: Vasterad
* URL: http://purethemes.net
*/

/* ------------------------------------------------------------------- */
/* Table of Contents
----------------------------------------------------------------------
	01. Import Section ...................... Imported variables.

	02. Layout Components ................... Generic layout elements.
		# Header
		# Navigation
		# Search / Cart Icon
		# jPanel mobile menu
		# Featured Services
		# Projects Categories
		# Projects Grid
		# Stages Widget
		# Counters
		# Testimonials
		# Back To Top
		# Footer
		# Owl Carousel Styles
		# Titlebar
		# Single Project Page
		# Services #1
		# Services #2
		# Single Service Page
		# Shop
		# Blog Styles
		# Recent Posts
		# Blog Widgets
		# Widgets
		# Image Edge
		# Contact Form
		# Google Maps

	03. Script Styles ....................... jQuery add-ons CSS.
		# Flexslider
		# Owl Carousel
		# TwentyTwenty
		# Magnific Popup
		# PhotoGrid

	04. Shortcodes .......................... Template elements.
		# Buttons
		# Tooltips
		# List Styles
		# Table
		# Tabs
		# Pricing Tables
		# Accordion / Toggles
		# Dropcaps
		# Parallax Banner
		# Info Banner
		# Info Banner Full Width
		# Team Members
		# Social Icons
		# Notification boxes
		# Pagination

	05. Others .............................. CSS helper classes etc.

	06. Media Queries ....................... Mobile style sheets.
*/


/* ------------------------------------------------------------------- */
/* 01. Import Section
---------------------------------------------------------------------- */
@import url("bootstrap3.css");					/* Default Reset, Typography, Forms, Responsive Grid etc. */
@import url("icons.css");						/* Webfont Icons: FontAwesome, Line, Simple Line */
@import url("revolutionslider.css");			/* Revolution Slider Style Sheets */
@import url("http://fonts.googleapis.com/css?family=Montserrat:400,700");
@import url("http://fonts.googleapis.com/css?family=Lato:400,700,300");


/* ------------------------------------------------------------------- */
/*  02. Layout Components
---------------------------------------------------------------------- */

/* ---------------------------------- */
/* Header
------------------------------------- */

/* Top Border */
body { border-top: 5px solid #666;}


/* Logo */
#logo {
	margin-top: 4px;
}

.header { margin: 30px 0 28px 0;}


/* Header Widget */
.header-widget {
	margin-top: 8px;
	float: right;
	padding-left: 0;
}

.header-widget li {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	padding-right: 35px;
	line-height: 24px;
	position: relative;
}

.header-widget li:after{
	position: absolute;
	height: 48px;
	content: "";
	display: inline-block;
	width: 1px;
	background-color: #e0e0e0;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	top: 50%;
	right: 0;
}

.header-widget li i {
	float: left;
	display: inline-block;
	color: #666;
	font-size: 34px;
	width: 35px;
	position: relative;
	margin-top: 8px;
	margin-right: 18px;
	text-align: center;
}

.header-widget li .widget-content { float: left; max-width: 150px;  }
.header-widget .sl-icon-location { font-size: 36px; margin: 5px 10px 0 8px;}

.header-widget li span.title {
	display: block;
	color: #333;
}

.header-widget li span.data { color: #808080; }
.header-widget a.button { margin-left: 13px }

.header-widget li:last-child { margin-right: 0; padding-right: 0;}


/* ---------------------------------- */
/* Navigation
------------------------------------- */
.menu {
	padding: 0;
	list-style: none;
	float: left;
	width: 100%;
	background-color: #fff;
	margin:0;
	z-index: 99;
	border-top: 1px solid #e0e0e0;
}

.menu ul { padding: 0; }
.menu > li {
	float: left;
}

.menu > li > a {
	display: block;
	position: relative;
}

.menu li { list-style: none; position: relative;}

.menu > li > a {
	color: #333;
	padding: 0;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	margin-right: 5px;
	padding: 22px 15px;
	border-bottom: 3px solid #fff;
}


.menu > li a:hover {
	border-color: #666;
	color: #666;
}


.menu > li,
.menu > li a:hover {
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition:  all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition:all 0.25s ease-in-out;
}


/* Mega Menu */


/* Basic Dropdown */
.dropdown ul {
	position: absolute;
	display: none;
	top: 100%;
	left: 0;
	z-index: 999;
	margin-top: -3px;
	background-color: #262626;
	border-top: 3px solid #666;
}

.dropdown ul li {
	position: relative;
	z-index: 999;
}

.dropdown ul ul {
	left: 201px;
	top: 0;
}

li.dropdown ul li a {
	display: block;
	min-width: 200px;
	color: #bbb !important;
	font-size: 14px;
	line-height: 22px;
	padding: 9px 22px;
	-webkit-transition: all 0.15s ease-in-out !important;
	transition: all 0.15s ease-in-out !important;
	position: relative;}

li.dropdown ul li a:hover {
	color: #fff !important;
	background-color: #666;
}


/* Menu Arrows*/


/* ---------------------------------- */
/* Search / Cart Icon
------------------------------------- */


/* ---------------------------------- */
/* jPanel mobile menu
------------------------------------- */
.menu-responsive { display: none; }

@media only screen and (max-width: 991px) {

	.menu {max-height: none;}
	#responsive { display: none; }
	
	.menu-responsive  { display: block }

	.menu-responsive {
		color: #fff;
		display: block;
		text-transform: uppercase;
		font-weight: 600;
		float: left;
		background-color: #666;
		position: relative;
		width: 100%;
		margin: 0px 0 25px 0;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.menu-responsive i {
		padding: 20px;
		margin: 0;
		font-size: 18px;
		font-weight: 500;
		cursor: pointer;
		width: 100%;
	}

	.menu-responsive i.menu-trigger { width: 100%; }

	.menu-responsive i.menu-trigger:after {
		content: "Menu";
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		top: -1px;
		position: relative;
		left: 10px;
	}

}



/* ---------------------------------- */
/* Featured Services
------------------------------------- */
.featured-service-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 30px;
}


a.featured-service {
	background-color: #f5f5f5;
	height: 176px;
	padding: 37px 40px;
	display: inline-block;
	margin-bottom: 30px;
}

.featured-service h4 { font-size: 16px; }
.featured-service p { color: #666; }

.featured-service i {
	font-size: 72px;
	color: #666;
	float: left;
	display: inline-block;
	margin-right: 29px;
	line-height: 95px;
	height: 176px;
}

a.featured-service:hover {
	background-color: #666;
}

a.featured-service,
a.featured-service h4,
a.featured-service p,
a.featured-service i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

a.featured-service:hover h4,
a.featured-service:hover p,
a.featured-service:hover i { color: #fff; }



/* ---------------------------------- */
/* Project Categories
------------------------------------- */


/* See All Projects */

/* Latest */


/* Full Width Button */


/* ---------------------------------- */
/* Projects Grid
------------------------------------- */


/* Filtering Styles*/


/* Projects Grid */


/* Overlay background */


/* Plus Icon */


/* Overlay content */


/* ---------------------------------- */
/* Stages Widget
------------------------------------- */
.stage {
	text-align: center;
	margin-top: 45px;
}

.stage i {
	width: 100%;
	display: block;
	font-size: 100px;
	color: #666;
	height: 125px;
}

.stage span {
	color: #666;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}

.stage h4 {
	margin: 4px 0;
	line-height: 26px;
}

.stage p {
	margin-top: 20px;
}


/* ---------------------------------- */
/* Counters
------------------------------------- */

/* ---------------------------------- */
/* Testimonials
------------------------------------- */


/* ---------------------------------- */
/* Back to top
------------------------------------- */
#backtotop {
	position: fixed;
	right: 0;
	display: none;
	bottom: 20px;
	margin: 0 20px 0 0;
	z-index: 9999;
}

#backtotop a {
	text-decoration: none;
	border: 0 none;
	display: block;
	width: 46px;
	height: 46px;
	background-color: rgba(0,0,0,0.3);
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

#backtotop a:after {
	content: "";
	font-family: "FontAwesome";
	position: relative;
	display: block;
	top: 9px;
	left: 15px;
}

#backtotop a:hover { background-color: #666; }


/* ---------------------------------- */
/* Footer
------------------------------------- */
#footer {
	background-color: #202020;
	padding: 50px 0 0 0;
	color: #999;
}

#footer h4 {
	color: #fff;
	font-size: 16px;
	margin-bottom: 20px;
}

/* Social Btn */


#footer a.button { margin-top: 12px; }
#footer a.button:hover { background-color: #fff; color: #333; }

.text-widget span { color: #ddd; }

/*  Links  */
ul.footer-links {
	margin: 0 0 15px 0;
	padding: 0;
	width: 50%;
	box-sizing: border-box;
	display: inline-block;
	float: left;
}

.footer-links li { position: relative; list-style: none; }

.footer-links li a {
	padding: 0 0 7px 13px;
	display: inline-block;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #aaa;
	line-height: 21px;
}

.footer-links li a:hover {
	padding-left: 16px;
	color: #fff !important;
}

.footer-links li:before {
	font-family: "FontAwesome";
	font-size: 12px;
	content: "";
	color: #fff;
	position: absolute;
	left: 0;
	top: 0px;
	padding: 0 7px 0 0;
	color: #ddd;
}


/* Footer Bottom */
.copyrights {
	color: #777;
	border-top: 1px solid #333;
	margin-top: 35px;
	text-align: center;
	padding: 30px 0;
	display: inline-block;
	width: 100%;
}
.copyrights a { color: #ddd; }


/* ---------------------------------- */
/* Owl Carousel Styles
------------------------------------- */


/* ---------------------------------- */
/* Titlebar
------------------------------------- */
#titlebar {
	background-color: #f6f6f6;
	position: relative;
	padding: 48px 0;
	margin-bottom: 55px;
}

#titlebar span a,
#titlebar span {
	font-size: 18px;
	color: #888;
	margin-bottom: 3px;
	display: inline-block;
}


#titlebar h2 {
	font-size: 24px;
	line-height: 32px;
	margin: 1px 0 2px 0;
}

/* Breadcrumbs */
#breadcrumbs {
	position: absolute;
	right: 0;
	display: inline-block;
	font-size: 13px;
	font-weight: 500;
	color: #888;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

#breadcrumbs ul { margin: 0; }

#breadcrumbs ul li a {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #888;
}

#breadcrumbs ul li a:hover { color: #666 }

#breadcrumbs ul li {
	display: inline-block;
	list-style: none;
	margin: 0 0 0 10px;
}

#breadcrumbs ul li:first-child:before { display: none; }

#breadcrumbs ul li:before {
	content: "\f105";
	font-family: "FontAwesome";
	color: #a0a0a0;
	font-size: 14px;
	margin-right: 15px;
}


/* ---------------------------------- */
/* Single Project Page
------------------------------------- */

/* Images Hover */
.img-hover {
	position: relative;
	display: inline-block;
	overflow: hidden;
	margin-bottom: 30px;
	width: 100%;
}

.img-hover img { width: 100%; }

.img-hover:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(34,34,34,0.15);
	opacity: 0;
	top: 0;
	left: 0;
	transition: all 0.25s;
	-webkit-transition: all 0.25s;
}

.img-hover:hover:before { opacity: 1; }

.img-hover[title]:after {
	content: attr(title);
	position: absolute;
	bottom: 0;
	left: 0;
	display: inline-block;
	background-color: rgba(34,34,34,0.5);
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	line-height: 23px;
	padding: 8px 16px;
}


/* Details  */

/* Project Nav */


/* ---------------------------------- */
/* Services #1
------------------------------------- */


/* Icon */

/* ---------------------------------- */
/* Services #2
------------------------------------- */

/* Plus Icon */


/* ---------------------------------- */
/* Single Service Page
------------------------------------- */

/* Services List */
.services-list li {
	padding: 0;
	list-style: none;
	margin-bottom: 5px;
	position: relative;
}

.services-list li a {
	color: #fff;
	padding: 19px 26px;
	width: 100%;
	display: inline-block;
	background-color: #f6f6f6;
	color: #333;
	font-size: 14px;
	-wekbit-transition: all 0.3s;
	transition: all 0.3s;
	font-weight: 600;
}


.services-list li a:after {
	content: "\f105";
	font-family: "FontAwesome";
	position: absolute;
	right: 25px;
	font-size: 18px;
	color: #888;
	-wekbit-transition: all 0.3s;
	transition: all 0.3s;
}

.services-list li a.active,
.services-list li a:hover {
	background-color: #0181db;
	color: #fff;
}

.services-list li a.active:after,
.services-list li a:hover:after {
	color: #fff;
}

.services-list {
	padding: 0;
}

/* Sidebar Button */


/* Sidebar textbox */
.sidebar-textbox {
	background-color: #f7f7f7;
	display: inline-block;
	width: 100%;
	margin: 20px 0 30px 0;
	color: #888;
	padding: 32px 35px;
}

.sidebar-textbox.color {
	background-color: #666;
	color: #fff;
}

.sidebar-textbox.color  h4 {color: #fff;}
.sidebar-textbox h4 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	margin-bottom: 16px;
}

.sidebar-textbox span { color: #666; display: inline-block; }
.sidebar-textbox.color span { color: #fff; }


/* ---------------------------------- */
/* Shop
------------------------------------- */

/* Shop Item */



/* Product Details */


/* Quantity
------------------------------------- */


/* Shopping Cart */

/* Shopping Cart
------------------------------------- */


/* Cart Totals */

/* Responsive Tables */

.stacktable { width: 100%; }


/* Price Range */



/* ---------------------------------- */
/* Blog Styles
------------------------------------- */


/* ---------------------------------- */
/* Recent Posts
------------------------------------- */


/* Comments
------------------------------------- */
.comments { margin: -10px 0 0 0; }
.comments h4 { margin-bottom: 25px; font-size: 20px; }

.comments .button {
	margin: 0 0 10px 90px;
	padding: 7px 15px;
}

.comments ul {padding-left: 0;}
.comments ul li {
	display: block;
	margin: 0 0 20px 90px;
	position: relative;
}

.comments ul li ul li {
	margin: 20px 0 0 90px;
}

/* Add Comment */


/* ---------------------------------- */
/* Blog Widgets
------------------------------------- */


/* Search Widget */
.input { position: relative; }

/* Need support widget */


/* Blog Widget Tabs */

/* Blog Tabs */


/* ---------------------------------- */
/* Widgets
------------------------------------- */


/* ---------------------------------- */
/* Image Edge
------------------------------------- */

/* Photo */

/* List */



/* ---------------------------------- */
/* Contact Form
------------------------------------- */
#contact textarea {
	min-height: 200px;
	margin: 15px 0 25px 0;
}


/* ---------------------------------- */
/* Google Maps
------------------------------------- */




/* ------------------------------------------------------------------- */
/*  03. Scripts Style Sheets
---------------------------------------------------------------------- */

/* ---------------------------------- */
/* Flexslider
------------------------------------- */

.zeus .tp-title-wrap {
	background: #666;
	width: 120%;
	height: 120%;
	top: 0;
	position: absolute;
	opacity: 0;
 }

.zeus .tp-title-wrap {
	-webkit-transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
	-moz-transition:all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
	-o-transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
	-ms-transition:all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
	transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
}



/* ---------------------------------- */
/* Owl Carousel
------------------------------------- */

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */

/*
 *  Core Owl Carousel CSS File
 */

/* No Js */

/*
 * 	Owl Carousel - Lazy Load Plugin
 */


/* ---------------------------------- */
/* TwentyTwnety (Before & After Slider)
------------------------------------- */


/* ---------------------------------- */
/* Magnific Popup
------------------------------------- */

/* Close Button */


/* Popup */

/* animate in */

/* animate out */

/* Dark overlay, start state */
/* animate in */
/* animate out */


/* Main image in popup */

/* The shadow behind the image */


/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {

  /* The shadow behind the image */
}


/* ---------------------------------- */
/* Photogrid
------------------------------------- */


/* ------------------------------------------------------------------- */
/*  04. Shortcodes
---------------------------------------------------------------------- */

/* Headline */
h3.headline {
	margin: 58px 0 45px 0 ;
}

h3.headline.with-border { padding-bottom: 35px;}

.headline span.color { color: #666; }

.headline.centered { text-align: center; }
.headline.with-border { border-bottom: 1px solid #e0e0e0; width: 100%; padding-bottom: 25px; }


/* ---------------------------------- */
/* Buttons
------------------------------------- */
input[type="button"],
input[type="submit"],
a.button.border,
a.button {
	background-color: #666;
	top: 0;
	padding: 10px 18px;
	color: #fff;
	text-transform: uppercase;
	position: relative;
	font-size: 13px;
	font-weight: 600;
	display: inline-block;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

a.button.medium {
	font-size: 14px;
	padding: 12px 22px;
}

a.button:hover,
input[type="button"]:hover,
input[type="submit"]:hover { background-color: #333; color: #fff; }


/* Button with border and effect */
a.button.border {
	background-color: transparent;
	border: 2px solid #666;
	color: #666;
}

.button.border {
	display: block;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

a.button.border {
	-webkit-transition: border-color 0.4s, color 0.4s;
	transition: border-color 0.4s, color 0.4s;
}

a.button.border::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #666;
	z-index: -1;
	opacity: 0;
	-webkit-transform: scale3d(0.7, 1, 1);
	transform: scale3d(0.7, 1, 1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

a.button.border,
a.button.border::before {
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}


a.button.border:hover::before {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

a.button.border:hover {
	color: #fff;
	border-color: #666;

}


/* ---------------------------------- */
/* Tooltips
------------------------------------- */


/* ---------------------------------- */
/* List Styles
------------------------------------- */


.list-1 {
	padding: 3px 0 0 0;
}


.list-1 li {
	list-style: none;
	margin: 14px 0;
	line-height: 27px;
	margin-left: 22px;
	position: relative;
}

.list-1.alt li { margin: 12px 0; margin-left: 22px; }


.list-1 li:first-child {
	margin-top: 0;
}



.list-1 li:before {
	font-family: "FontAwesome";
	margin: 0;
	position: relative;
	color: #666;
	float: left;
	margin-left: -22px;
	display: block;
}
.list-1 li:before { content: "\f0c8"; font-size: 8px; }


/* ---------------------------------- */
/* Table
------------------------------------- */



/* ---------------------------------- */
/* Tabs
------------------------------------- */


/* ---------------------------------- */
/* Pricing Tables
------------------------------------- */

/* Colors */




/* ---------------------------------- */
/* Accordion / Toggles
------------------------------------- */



/* ---------------------------------- */
/* Dropcaps
------------------------------------- */


/* ---------------------------------- */
/* Parallax Banner
------------------------------------- */
.parallax-banner {
	position: relative;
	background-size: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 90px 0;
}

.parallax-banner:before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	background-color: transparent;
	background-color: rgba(34,34,34,0.5)
}

.parallax-title {
	position: relative;
	z-index: 10;
	font-size: 42px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	display: block;
	left: 0;
	text-align: center;
	line-height: 46px;
}

.parallax-title span {
	display: block;
	padding: 10px 0 0 0;
	font-size: 23px;
	font-weight: 300;
	opacity: 0.65;
	line-height: 32px;
}


/* ---------------------------------- */
/* Info Banner
------------------------------------- */


/* ---------------------------------- */
/* Info Banner Full Width
------------------------------------- */
.info-banner-fw {
	background-color: #666;
	font-size: 32px;
	font-weight: 300;
	line-height: 40px;
	color: #fff;
	padding: 45px 0;
	text-align: center;
	margin: 0 0 0px 0;
}

.info-banner-fw a {
    display: inline-block;
	color: #fff;
	font-weight: 600;
	/*font-size: 14px;*/
	border: 2px solid #fff;
	/*background-color: transparent;*/
	/*text-transform: uppercase;*/
	padding: 15px 20px;
	margin: 16px 0 0 0;
	/*position: relative;*/
	/*top: -7px;*/
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.info-banner-fw a:hover {
    border: 2px solid #fff;
    background-color: #fff;
	/*color: #fff !important;*/
}


/* ---------------------------------- */
/* Team Members
------------------------------------- */



/* ---------------------------------- */
/* Social Icons
------------------------------------- */

/* Common Styles */


/* Fixed Style */


/* Icon Styles */


/* Web Font */
@font-face {
  font-family: 'fontello';
  src: url('../../assets/fonts/fontello.eot?31771571');
  src: url('../../assets/fonts/fontello.eot?31771571#iefix') format('embedded-opentype'),
	   url('../../assets/fonts/fontello.woff?31771571') format('woff'),
	   url('../../assets/fonts/fontello.ttf?31771571') format('truetype'),
	   url('../../assets/fonts/fontello.svg?31771571#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
} /* '#' */ /* '$' */ /* '+' */ /* '/' */ /* '0' */ /* '1' */ /* ':' */ /* ';' */ /* '=' */ /* '&gt;' */ /* 'A' */ /* 'B' */ /* 'D' */ /* 'E' */ /* 'F' */ /* 'L' */ /* 'R' */ /* 'T' */ /* 'U' */ /* 'V' */ /* 'X' */ /* 'Y' */ /* 'a' */ /* 'b' */ /* 'd' */ /* 'f' */ /* 'g' */ /* 'l' */ /* 't' */ /* 'w' */ /* '?' */ /* '?' */


/* ---------------------------------- */
/* Notification Boxes
------------------------------------- */


/* ---------------------------------- */
/* Pagination
------------------------------------- */


/* ------------------------------------------------------------------- */
/*  05. Others
---------------------------------------------------------------------- */

	/* Boxed & Full Width Layout */

	#wrapper { background-color: #fff; }
	#logo img { background-color: transparent !important; }


	/* Drop points, do not edit */


	/* Main Font */
	body, input[type="text"], input[type="email"], textarea, .tp-caption .caption-text, select {
		font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; text-transform: none;
	}


	/* Secondary Font */
	 h2, h3, h4,  .button,  input[type="button"],
	input[type="submit"], button, .tp-caption, .menu > li > a, .stage span,
	#breadcrumbs, .img-hover::after,
	.services-list, .parallax-title, .info-banner-fw a, .menu-responsive i.menu-trigger:after
	{ font-family: "Montserrat", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; letter-spacing: 1px; text-transform: uppercase; }


	/* Demo Styles */


	/* ---------------------------------- */
	/* Spacers
	------------------------------------- */

	/* Margin Top */
	.margin-top-10 { margin-top: 10px !important; }
	.margin-top-35 { margin-top: 35px !important; }
	.margin-top-45 { margin-top: 45px !important; }
	.margin-top-50 { margin-top: 50px !important; }

	/* Margin Bottom */
	.margin-bottom-0  { margin-bottom: 0 !important; }
	.margin-bottom-10 { margin-bottom: 10px !important; }
	.margin-bottom-20 { margin-bottom: 20px !important; }
	.margin-bottom-35 { margin-bottom: 35px !important; }

	.extra-gutter-left { padding-left: 25px; }



/* ------------------------------------------------------------------- */
/* 06. Media Queries
---------------------------------------------------------------------- */

/* Standard 1180 (devices and browsers) */
@media only screen and (min-width: 1240px) {

}


/* Smaller than standard 1180 (devices and browsers) */
@media (max-width: 1239px) {

	.featured-service-container {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	a.featured-service {
	    height: auto;
	    padding: 32px 35px;
	}

	a.featured-service i {
		margin-right: 23px;
		font-size: 64px;
		height: auto
	}

	.parallax-banner { padding: 80px 0 75px 0;}
	.parallax-title { font-size: 36px;}
	.parallax-title span { font-size: 22px;}

}


/* Tablet Portrait size to standard 960 (devices and browsers) */
@media (max-width: 991px) {

	h3.headline { margin: 45px 0 35px 0; }

	.header-widget {
		float: none;
		margin: 0;
		width: 100%;
		display: inline-block;
		text-align: center;
	}

	.header-widget li {
		text-align: left;
		margin-top: 35px;
		margin-bottom: 7px;
	}

	#logo {
	    margin-top: 5px;
	    width: 100%;
	    border-bottom: 1px solid #e0e0e0;
	    padding-bottom: 35px;
	    text-align: center;
	}

	h3.headline.with-border { line-height: 32px; }
	.copyrights { text-align: left; }
	ul.services-list { margin-bottom: 40px; }
	.sidebar-textbox { margin-top: 0; }

	.info-banner-fw a {
		display: block;
		box-sizing: border-box;
		float: none;
		top: 15px;
		padding: 14px 10px;
		line-height: 22px;
		margin-left: 0;
	}

	.info-banner-fw { text-align: center; font-size: 30px; padding-bottom: 65px; }

}


/* All Mobile Sizes (devices and browser) */
@media (max-width: 767px) {

	h3.headline { margin: 48px 0 35px 0; }

	.header-widget { margin-top: 10px; }
	.header-widget li:after {
		display: none;
	}

	.header-widget li {
		margin: 20px 15px 5px 0px;
		padding: 0;
	}

	.header-widget li.with-btn {
		width: 100%;
		text-align: center;
	}

	.header-widget li a.button { margin-left: 0; }

	#titlebar { padding: 40px 0 50px 0;}
	#titlebar #breadcrumbs { position: relative; }
	#titlebar span { margin-bottom: 15px; }

	#titlebar #breadcrumbs ul {
		padding-left: 0;
		position: relative;
		top: 0;
		-webkit-transform: translateY(50%);
		-moz-transform: translateY(50%);
		-ms-transform: translateY(50%);
		transform: translateY(50%);
	    left: -10px;
	}

	#titlebar span a,
	#titlebar span { display: block; }

	.featured-service-container {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}

	a.featured-service {
	    width: 100%;
	    padding: 25px 27px;
	    margin-bottom: 20px;
	}

	a.featured-service h4 { margin-top: 20px; }

	.stage { margin-bottom: 45px; }

	.parallax-title { font-size: 32px; }

	.extra-gutter-left { padding: 0 15px;}

}



/* ------------------------------------------------------------------- */
/* Styles Switcher
---------------------------------------------------------------------- */

	.colors {
		list-style:none;
		margin: 0 0px 10px 0;
		overflow: hidden;
	}

	.colors li {
		float:left;
		margin: 4px 2px 0 2px;
	}

	.colors li a {
		display: block;
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	.red { background: #dd3737; }

	.colors li a.active { box-shadow: 0 0 0 2px #fff inset }
